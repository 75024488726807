
export default {
  name: "PricingButton",
  props: ['title', 'plan', 'lnk'],
  mounted() {

  },
  methods: {
    relink() {
      if (this.lnk) {
        this.$router.push({ path: `${this.lnk}`})
      } else {
        this.$router.push({ path: `${this.plan}`})
      }

    }
  }
}
