
import BoxWithIcon from "./BoxWithIcon";
import data from "@/mixins/data.js"


export default {
  name: "BoxSection",
  props: ['type'],
  components: {
    BoxWithIcon,
  },
  computed: {
    items() {
      return this.type === 'main' ? this.boxWidthItemMain : this.boxWidthItem
    }
  },
  mixins: [data],
  data() {
    return {

    }
  },
}
